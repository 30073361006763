import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { ExtraType } from "../interfaces";

type StyledButtonProps = {
  onClick?: () => void;
  isDisabled?: boolean;
  children: React.ReactNode;
  extra?: ExtraType;
  id?: string;
  variant?: "primary" | "secondary" | "simple";
  style?: CSSProperties;
  className?: string;
};
const StyledButton = (props: StyledButtonProps) => {
  const {
    onClick,
    extra = ``,
    isDisabled,
    id,
    variant = "primary",
    style,
    className,
  } = props;
  return (
    <CustomButton
      onClick={onClick}
      className={className}
      disabled={isDisabled}
      extra={extra}
      style={style}
      id={id}
      variant={variant}
    >
      {props.children}
    </CustomButton>
  );
};

export default StyledButton;

export const CustomButton = styled.button<{
  extra?: ExtraType;
  variant?: string;
}>`
  border-radius: 4px;
  width: 100%;
  max-width: 411px;
  padding: 0;
  box-sizing: border-box;
  //height: 100%;
  height: 41px;
  max-height: 53px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: "Roboto Condensed", "serif";

  ${({ variant, theme }) =>
    variant === "secondary"
      ? css`
          color: ${theme.palette.button.secondary.color};
          background-color: ${theme.palette.button.secondary.background};
          border: 1px solid ${theme.palette.button.secondary.border};

          @media (hover: hover) {
            &:enabled:hover {
              color: ${theme.palette.button.secondary.hover_color};
              background-color: ${theme.palette.button.secondary
                .hover_background};
              box-shadow: ${theme.palette.button.secondary.hover_shadow};
              border: 1px solid ${theme.palette.button.secondary.hover_border};
            }
          }
        `
      : variant === "simple"
      ? css`
          color: ${theme.palette.button.simple.color};
          background-color: ${theme.palette.button.simple.background};
          border: 1px solid ${theme.palette.button.simple.border};

          @media (hover: hover) {
            &:enabled:hover {
              color: ${theme.palette.button.simple.hover_color};
              background-color: ${theme.palette.button.simple.hover_background};
              border: 1px solid ${theme.palette.button.simple.hover_border};
            }
          }
        `
      : css`
          color: ${theme.palette.button.primary.color};
          background-color: ${theme.palette.button.primary.background};
          border: 1px solid ${theme.palette.button.primary.border};

          @media (hover: hover) {
            &:enabled:hover {
              color: ${theme.palette.button.primary.hover_color};
              background-color: ${theme.palette.button.primary
                .hover_background};
              box-shadow: ${theme.palette.button.secondary.hover_shadow};
              border: 1px solid ${theme.palette.button.primary.hover_border};
            }
          }
        `};

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.palette.color.disabled};
  }

  ${({ extra }) => extra};
`;
