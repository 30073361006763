import {AppSelector} from "../store";
import {createSelector} from "@reduxjs/toolkit";

const modalState: AppSelector<boolean> = (state) => state.appSlice.modal
const modalRegistrationState: AppSelector<boolean> = (state) => state.appSlice.modalRegistration
const modalLoginState: AppSelector<boolean> = (state) => state.appSlice.modalLogin
const modalResetPasswordState: AppSelector<boolean> = (state) => state.appSlice.modalResetPassword
const modalCheckPhoneState: AppSelector<boolean> = (state) => state.appSlice.modalCheckPhone
const modalCheckEmailState: AppSelector<boolean> = (state) => state.appSlice.modalCheckEmail
const modalPromotionState: AppSelector<boolean> = (state) => state.appSlice.modalPromotion
const modalPopupState: AppSelector<boolean> = (state) => state.appSlice.modalPopup
const modalProvidersState: AppSelector<boolean> = (state) => state.appSlice.modalProviders
const modalConfirmState: AppSelector<boolean> = (state) => state.appSlice.modalConfirm

export const selectModal = createSelector(modalState, modal => modal)
export const selectModalRegistration = createSelector(modalRegistrationState, modal => modal)
export const selectModalLogin = createSelector(modalLoginState, modal => modal)
export const selectModalResetPassword = createSelector(modalResetPasswordState, modal => modal)
export const selectModalCheckPhone = createSelector(modalCheckPhoneState, modal => modal)
export const selectModalCheckEmail = createSelector(modalCheckEmailState, modal => modal)
export const selectModalPromotion = createSelector(modalPromotionState, modal => modal)
export const selectModalPopup = createSelector(modalPopupState, modal => modal)
export const selectModalProviders = createSelector(modalProvidersState, modal => modal)
export const selectModalConfirm = createSelector(modalConfirmState, modal => modal)

export const selectModals = createSelector(
    modalState,
    modalRegistrationState,
    modalLoginState,
    modalResetPasswordState,
    modalCheckPhoneState,
    modalCheckEmailState,
    modalPromotionState,
    modalPopupState,
    modalProvidersState,
    modalConfirmState,
    (modal, register, login, resetPassword, checkPhone, checkEmail, promotion, popup, providers, confirm) => ({
        modal,
        register,
        login,
        resetPassword,
        checkPhone,
        checkEmail,
        promotion,
        popup,
        providers,
        confirm
    })
)