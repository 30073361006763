import styled from "styled-components";
import HeaderOne from "../customText/headers/HeaderOne";
import StyledButton from "ui/StyledButton";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "assets/images/payment-result-modal/success.svg";
import { ReactComponent as ErrorIcon } from "assets/images/payment-result-modal/error.svg";
import Modal from "ui/material-kit/Modal";
import { ReactComponent as CrossIcon } from "assets/images/input/input_cross_icon.svg";
import { selectIsMobile } from "redux/selectors/appSelector";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useEffect, useState } from "react";
import { ProjectSettings } from "config";

const PaymentResultModal = () => {
  const [type, setType] = useState<"success" | "error" | null>(null);
  const project = ProjectSettings.variant();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useTypedSelector(selectIsMobile);

  console.log("test deploy front");

  const handleClose = () => {
    setType(null);
    searchParams.delete("payment");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const checkSearchParams = () => {
      const payment = searchParams.get("payment");
      if (payment === "0") {
        setType("error");
      }
      if (payment === "1") {
        setType("success");
      }
    };

    checkSearchParams();
  }, [searchParams]);

  return (
    <>
      <Modal open={!!type && project === "lettobet"} onClose={handleClose}>
        <ModalWrapper>
          <IconContainer isMobile={isMobile}>
            <ExitIcon onClick={handleClose}>
              <CrossIcon />
            </ExitIcon>
          </IconContainer>
          <Container>
            {type === "success" ? <SuccessIcon /> : <ErrorIcon />}
            <Title>
              {t(
                type === "success"
                  ? "common.modal.payment_result.success_title"
                  : "common.modal.payment_result.error_title"
              )}
            </Title>
            <Text>
              {t(
                type === "success"
                  ? "common.modal.payment_result.success_text"
                  : "common.error.400"
              )}
            </Text>
          </Container>

          <Button
            onClick={() => {
              type === "success"
                ? navigate("/games")
                : navigate("/profile/deposit");
              handleClose();
            }}
          >
            {t(
              type === "success"
                ? "common.modal.payment_result.success_button_text"
                : "common.registration.back"
            )}
          </Button>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default PaymentResultModal;

const ModalWrapper = styled.div`
  position: relative;
  padding: 63px clamp(20px, -0.192rem + 6.41vw, 120px);
  max-width: 80vw;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.background.modal};

  box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);
`;

const Title = styled(HeaderOne)`
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.color.payment_result_title};
  font-weight: 700;
  font-size: 36px;
  text-align: center;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.palette.color.payment_result_text};
  font-weight: 700;
  font-size: 16px;

  line-height: 24px;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const Button = styled(StyledButton)`
  margin-top: 32px;
  width: 249px;
`;

const IconContainer = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const ExitIcon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  margin-bottom: 0;
  cursor: pointer;

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.svg.main};
  }
`;
